import React from "react"
import "../components/timmi.css"
import { StaticQuery, graphql } from "gatsby"
import Starring from "../components/oh-deck/Starring"
import mengeMengeMenge from "../images/mengeMengeMenge.png"
export default function Portfolio() {
  return (
    <>
      <section className="timmiPage" id="portfolio">
      <h2 className="pageHeading">portfolio</h2>
        <Starring
          title="Mandelbrot Menge Menge Menge"
          text="In September and October 2017, Kurt Stettler, and myself - Tiemo Hunger
          were honored hosts of an exhibition in the gallery of claudine hohl,
          at the banks of the beatuiful and silent zurich schanzengraben area.
          "
          pic={mengeMengeMenge}
          caption="kurt stetter and tiemo"
          more="asdf"
          moreLink="/mandelbrotMengeMengeMenge"
        ></Starring>
      </section>
    </>
  )
}
